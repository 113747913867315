<template>
  <div>
    <app-narrow v-if="width < 1000" />
    <app-wide v-if="width > 999" />
  </div>
</template>

<script>
import narrow from '../components/customisation/AddNarrow.vue';
import wide from '../components/customisation/AddWide.vue';

export default {
  name: 'Customisation',
  components: {
    appNarrow: narrow,
    appWide: wide,
  },
  computed: {
    width() {
      return window.innerWidth;
    },
  },
  mounted() {
    this.$store.commit('garmentDeSelector');
  },
};
</script>
